import React from "react";
import { graphql } from "gatsby";
import BackupForm from "@views/BackupForm";
import { BackupFormProps } from "src/types";

export const pageQuery = graphql`
  query BackupFormQuery {
    bgDesktop: file(relativePath: { eq: "bg/desktop/contact-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1542, maxHeight: 1028, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgMobile: file(relativePath: { eq: "bg/mobile/contact-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 695, maxHeight: 1157, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    formDesktop: file(
      relativePath: { eq: "bg/desktop/contact-form-image.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1350, maxHeight: 745, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    formMobile: file(relativePath: { eq: "bg/mobile/contact-form-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 616, maxHeight: 303, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iconPhone: file(relativePath: { eq: "icon/icon-phone.svg" }) {
      publicURL
    }
    logo: file(relativePath: { eq: "logo.svg" }) {
      publicURL
    }
  }
`;

export default function BackupFormRoot(props: BackupFormProps) {
  return <BackupForm {...props} />;
}

import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import BgImage from "gatsby-background-image";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import { isMobile } from "react-device-detect";
import SEO from "@components/SEO";
import dayjs from "dayjs";
import axios from "axios";
import { getURLParam } from "@helper/index";
import { BackupFormProps } from "src/types";

import styles from "./form.module.scss";

interface FormData {
  nama: string;
  no_hp: string;
  email: string;
}

export default function BackupForm(props: BackupFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [device, setDevice] = useState<string>("");
  const { register, handleSubmit, errors } = useForm();
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const {
    bgDesktop,
    bgMobile,
    formDesktop,
    formMobile,
    iconPhone,
    logo,
  } = props.data;

  useEffect(() => {
    if (isMobile) {
      setDevice("mobile");
    } else {
      setDevice("desktop");
    }
  }, []);

  const bg = [
    bgMobile.childImageSharp.fluid,
    {
      ...bgDesktop.childImageSharp.fluid,
      media: "(min-width: 992px)",
    },
  ];

  const formImg = [
    formMobile.childImageSharp.fluid,
    {
      ...formDesktop.childImageSharp.fluid,
      media: "(min-width: 992px)",
    },
  ];

  const onSubmit = (data: FormData) => {
    const payload = {
      action: "insert",
      no: "=ROW()-1",
      tanggal: dayjs().format("DD MMMM YYYY HH:mm:ss A"),
      nama: data.nama,
      no_hp: data.no_hp,
      email: data.email,
      source: device,
      keyword: getURLParam("utm_term"),
      campaign_id: getURLParam("utm_campaign"),
    };

    setIsLoading(true);

    axios
      .get(
        "https://script.google.com/macros/s/AKfycbzhff4s4kt0rCIeLIIhC4zUlHQ3qnXY5sIzr9YOv2SCWY0IUvWl8-Zka1ftdSypmaq-/exec",
        { params: payload }
      )
      .then(() => {
        setIsLoading(false);
        navigate("/contact/");
      })
      .catch(error => {
        console.error("Error : ", error);
        setIsLoading(false);
        alert("Terjadi kesalahan. Silahkan coba beberapa saat lagi.");
      });
  };

  return (
    <BgImage fluid={bg} Tag="section" className={styles.form}>
      <SEO title="Backup Form" />
      <div className={styles.container}>
        <BgImage fluid={formImg} className={styles.formImg}>
          <img src={logo.publicURL} alt="logo" className={styles.logo} />
        </BgImage>
        <div className={styles.formContainer}>
          <img src={iconPhone.publicURL} alt="phone icon" />
          {isDesktop ? null : (
            <p>
              Untuk kemudahan berkomunikasi, silahkan isi form di bawah ini:
            </p>
          )}
          <Form onSubmit={!isLoading ? handleSubmit(onSubmit) : undefined}>
            <Form.Group className={styles.inputGroup}>
              <Form.Control
                plaintext
                name="nama"
                placeholder="Nama"
                isInvalid={errors.nama}
                className={styles.input}
                ref={register({
                  minLength: {
                    value: 2,
                    message: "Nama tidak boleh kurang dari 2 karakter.",
                  },
                  required: "Nama tidak boleh kosong.",
                })}
              />
              <Form.Text>{errors.nama && errors.nama.message}</Form.Text>
            </Form.Group>
            <Form.Group className={styles.inputGroup}>
              <Form.Control
                plaintext
                name="no_hp"
                type="number"
                placeholder="No. Hp / Whatsapp"
                isInvalid={errors.no_hp}
                className={styles.input}
                ref={register({
                  minLength: {
                    value: 10,
                    message: "No. HP tidak boleh kurang dari 10 karakter.",
                  },
                  required: "No. HP tidak boleh kosong.",
                })}
              />
              <Form.Text>{errors.no_hp && errors.no_hp.message}</Form.Text>
            </Form.Group>
            <Form.Group className={styles.inputGroup}>
              <Form.Control
                plaintext
                name="email"
                type="email"
                placeholder="E-mail"
                isInvalid={errors.email}
                className={styles.input}
                ref={register({
                  required: "Email tidak boleh kosong",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Mohon masukkan alamat email yang valid.",
                  },
                })}
              />
              <Form.Text>{errors.email && errors.email.message}</Form.Text>
            </Form.Group>
            <p>
              Setelah mengisi form, Anda akan menuju
              <br />
              halaman informasi alamat dan nomor
              <br />
              telepon Gudang Seragam
            </p>
            <Button type="submit" className={styles.btnSubmit} block>
              {isLoading ? "MENGIRIM . . ." : "KIRIM"}
            </Button>
          </Form>
        </div>
      </div>
    </BgImage>
  );
}
